@tailwind base;
@tailwind components;
@tailwind utilities;

.leaflet-container *:focus {
    outline: none;
}

.leaflet-popup-content {
    
}

@layer base {
    * {
        @apply font-serif
    }
}

@layer components {
    .about-content {

    }
    .about-content h1 {
      @apply text-2xl m-2;
    }
    .about-content h2 {
      @apply text-xl m-1;
    }
    /* ... */
  }
